<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container">
      <div class="row">    
              <div class="col-md-12 col-12">
                 <div class="card-header">
                <h4 class="card-title"> Payment Receipt </h4>
              </div>
              </div> 
           
            </div>   
            
      </div>

     
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
           <div class="col-md-12 col-12" style="text-align:right;">
              <div class="srcpadmobie"> 
              <button @click="gereratePDF" class="btn btn-primary btn-sm"><i class="fa fa-print"></i> <span class="mhide">Print Invoice</span></button>
            </div>
            </div>
        </div>
        <div class="row">         
          <div class="offset-md-4 col-md-4">
            <div class="connectedSortable">
              <div class="row">
                <div class="col-md-12">                  
                  <table class="table" id="table">
                    <tr>
                      <td style="text-align:center;" colspan="2">
                        <p><span style="font-size:20px;"><b>NEW ATUL DRY CLEANER</b></span><br>
                        In front of Nagar Palika Gate, Etawah<br>
                        Payment Receipt</p>
                        </td>
                    </tr>                   
                    <tr>
                      <td class="left"><b>Name</b></td>
                      <td class="left"> {{name}}</td>
                    </tr>
                    <tr>
                      <td class="left"><b>Mobile No.</b></td>
                      <td class="left"> {{mobile_no}}</td>
                    </tr>
                    <tr>
                      <td class="left"><b>Date</b></td>
                      <td class="left"> {{pickup_date}}</td>
                    </tr>
                    <tr>
                      <td class="left"><b>Order No</b></td>
                      <td class="left"> #{{invoice_no}}</td>
                    </tr>
                    <tr>
                      <td class="left"><b>Amount Paid</b></td>
                      <td class="left"> Rs. {{this.amount}}</td>
                    </tr>
                    <tr>
                      <td class="left"><b>Balance</b></td>
                      <td class="left"> Rs. {{balance}}</td>
                    </tr>
                    
                  </table>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>

import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import Header from './Header.vue'
import Footer from './Footer.vue'
import Topbar from './Topbar.vue'
import axios from "axios";
export default { 
  extends:jsPDF, 
  components:{         
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  data() {
    return {
      company_id:this.$store.state.auth.user.data[0].company_id,
      invoice_id: this.$route.params.id, 
      amount: localStorage.getItem('payamount'),      
      item_list:[],
      additional_charge:[],
      order_type:this.order_type, 
      name:'',       
    };
  },

  methods: {
    gereratePDF(){        
          var doc = new jsPDF('p', 'pt', 'a4')  
            //var y = 20;  
            doc.setLineWidth(2);  
            //doc.text(70, y = y + 30, "NEW ATUL DRY CLEANER");  
            //doc.text(60, y = y + 7, "In front of Nagar Palika Gate, Etawah");    
            //doc.text(83, y = y + 8, "Payment Receipt");            
          doc.autoTable({ 
            startY: 70,
            theme: 'grid',
            margin: {
            right: 180,
            left: 180
            },            
            styles: {
            overflow: 'linebreak',            
            },  
            columnWidth: 'wrap',                            
            html: '#table' })                          
          doc.save('table.pdf')         
    }
  },
  
  mounted(){
    axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/getorderdetails",  {
    invoice_id: this.$route.params.id,
    company_id: this.company_id    
	})
      .then(res => {
        //alert(res.data.data)
        var resData = res.data.data[0];
        this.invoice_details = resData.invoice_details;        
        
        this.name = resData.invoice_details[0].name
        this.pickup_date = resData.invoice_details[0].pickup_date
        this.booking_date = resData.invoice_details[0].booking_date
        this.delivery_date = resData.invoice_details[0].delivery_date
        this.discount = resData.invoice_details[0].discount
        this.net_amount = resData.invoice_details[0].net_amount
        this.grand_amount = resData.invoice_details[0].grand_amount
        this.paid_amount = resData.invoice_details[0].paid_amount
        this.balance = resData.invoice_details[0].balance
        this.order_type = resData.invoice_details[0].order_type
        this.client_id = resData.invoice_details[0].client_id
        this.mobile_no = resData.invoice_details[0].mobile_no
        this.invoice_no = resData.invoice_details[0].invoice_no        

        console.log(this.invoice_details)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })
  }
};
</script>

<style>
.connectedSortable { background: #fff;  padding: 5px; }
.item {border: 1px solid #ddd;}
.qty {border: 1px solid #ddd; width: 150px;}
.amount {border: 1px solid #ddd; width: 150px;}
.right { text-align: right;}
.left { text-align: left; padding-left: 10px;}
.red { color:#ff0000;}
@media (max-width: 600px) {
  .mhide { display: none;}
  h3 { font-size: 24px !important;
    font-weight: 700 !important;}
    .left { text-align: left; font-size: 11px;}
}
  @import '../assets/dist/css/custom.css'
  
</style>

